<template>
  <div class="backcolor">
    <v-toolbar dense class="elevation-1">
      <v-icon color="blue" class="text-h4">mdi-human-male-board</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('school.school_titleprofessor') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="darkpink"
            rounded
            dark
            v-bind="attrs"
            v-on="on"
            @click="formTeacher = {}"
          >
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('school.school_criarprofessor') }}
          </v-btn>
          <v-btn color="darkpink" rounded dark v-on="on" style="left: -1%" @click="MenuCertificado()">
            <v-icon left> mdi-certificate </v-icon>
            {{ $t('certificado.cert_vercertificado') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('school.school_novoprofessor') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col class="d-flex" cols="12">
                    <v-text-field
                      color="darkblue"
                      v-model="formTeacher.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nome')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12">
                    <v-text-field
                      color="darkblue"
                      v-model="formTeacher.user"
                      :rules="[rules.required, rules.user, rules.exist]"
                      @change="verifyUser(formClient.username)"
                      :label="$t('label.label_usuario')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" v-if="!editMode">
                    <v-text-field
                      color="darkblue"
                      :label="$t('label.label_email.email_obr')"
                      :rules="[rules.email, rules.required]"
                      v-model="formTeacher.email"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col class="d-flex" cols="12">
                    <v-text-field
                      v-if="!editMode"
                      color="darkblue"
                      v-model="formTeacher.password"
                      :rules="[rules.required, rules.length(4)]"
                      :label="$t('label.label_senha.label_senhaob')"
                      :type="showPassword ? 'text' : 'password'"
                      required
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>{{ $t('regras.regra_obrigatorio') }}*</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" text rounded @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn
              color="darkpink"
              text
              rounded
              @click="
                editMode ? updateTeacher(formTeacher.id) : registerTeacher()
              "
            >
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >{{ $t('alerta.alerta_excluir.excluir3') }}</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >{{ $t('botoes.botao_cancelar') }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >{{ $t('botoes.botao_continuar') }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="ma-4">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="teacher in teachers"
        :key="teacher.id"
      >
        <v-card class="rounded-lg py-4 px-2" style="height: 100%">
          <div
            class="
              d-flex
              flex-column
              align-center
              justify-space-around
              text-center
            "
            style="height: 100%; min-height: 25vh"
          >
            <div v-if="teacher.avatar !== null">
              <v-img width="90" class="rounded-circle" :src="teacher.avatar">
              </v-img>
            </div>
            <div class="font-weight-bold" style="font-size: 1.2rem">
              {{ teacher.name }}
            </div>
            <div class="mb-4 font-weight-medium" style="font-size: 0.9rem">
              {{ teacher.user }}
            </div>
            <div style="font-size: 0.9rem">
              <div>{{ $t('student.student_label.label7') }} {{ teacher.email }}</div>
              <div>{{ $t('student.student_minhainformacao.informacao5') }} {{ teacher.phone }}</div>
            </div>
            <div class="mt-6">
              <v-btn
                rounded
                outlined
                small
                icon
                @click="editItem(teacher)"
                color="darkblue"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                rounded
                outlined
                small
                icon
                @click="deleteItem(teacher)"
                color="error"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
              <v-btn
                rounded
                outlined
                small
                icon
                @click="viewAppliedClasses(teacher)"
                color="primary"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-eye</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

      <!-- Diálogo para listar as aulas aplicadas -->
      <v-dialog v-model="appliedClassesDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            {{ $t('student.student_aulaplicadas') }} {{ selectedTeacherClasses.length > 0 ? selectedTeacherClasses[0].Professor : "" }}
            <v-spacer></v-spacer>
            <v-btn icon @click="appliedClassesDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          
          <v-card-text class="my-2" style="height: 70vh; overflow-y: scroll">
            <!-- Loop através do array de aulas -->
            <v-alert
              v-for="(aula, index) in selectedTeacherClasses"
              :key="aula.classId"
              :color="colorLookDark(index)"
              text
              dense
              outlined
              :icon="$vuetify.breakpoint.xsOnly ? false : 'mdi-bookshelf'"
              :prominent="!$vuetify.breakpoint.xsOnly"
              class="mb-3"
            >
              <v-row align="center" no-gutters>
                <v-col
                  cols="12"
                  sm="8"
                  lg="9"
                  class="grow font-weight-bold black--text"
                >
                  <div style="font-size: 1.1rem">
                    {{ aula.Aula }} <!-- Título da aula -->
                  </div>
                  <div style="font-size: 0.9rem">
                    {{ $t('homework.homework_detalhes.detalhes3') }} {{ aula.AulaDesc }} <!-- Projeto relacionado -->
                  </div>
                  <div style="font-size: 0.9rem">
                    {{ $t('student.student_label.label4') }} {{ aula.Turma }} <!-- Projeto relacionado -->
                  </div>
                  <div style="font-size: 0.9rem">
                    {{ $t('homework.homework_detalhes.detalhes2') }} {{ aula.Kit }} <!-- Projeto relacionado -->
                  </div>
                  <div style="font-size: 0.9rem">
                    {{ $t('school.school_data') }} {{ aula.Data }} 
                  </div>
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  lg="3"
                  class="pa-0 ma-0 d-flex"
                  :class="$vuetify.breakpoint.xsOnly ? 'flex-row' : 'flex-column'"
                >
                </v-col>
              </v-row>
            </v-alert>
          </v-card-text>

        </v-card>
      </v-dialog>
    </div>
  </template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      dialog: null,
      formTeacher: {},
      search: "",
      headers: [
        { text: this.$t('login.login_usuario.usuario3'), value: "name" },
        { text: this.$t('login.login_painel.login1'), value: "user" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      teachers: [],
      editedIndex: -1,
      dialogDelete: null,
      editMode: false,
      userExisting: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        none: true,
      },
      schools: [],
      selectedSchool: [],
      varias: null,
      searchDialog: true,
      teachersNot: [],
      searchTeachers: "",
      // IDdaEscola: 15,
      showPassword: false,

      appliedClassesDialog: false,
      selectedTeacherClasses: [],
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
    };
  },
  methods: {
    async verifyUser(res) {
      let response = await axios.get(`${url}/clients/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.data != null) {
        this.userExisting = true;
      } else {
        this.userExisting = false;
      }
    },
    MenuCertificado() {
      this.$parent.$parent.$parent.page = this.$t('certificado.cert_conteudo.conteudo1')
    },
    openSearchTeachers() {
      this.getTeachersNotLinked();
      this.searchDialog = true;
    },
    inscriptionTeacher(item) {
      const data = { teacherId: item.id };
      axios
        .post(`${url}/teacherInscriptions`, data, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getTeachersNotLinked();
          this.getTeacherBySchool();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeachersNotLinked() {
      axios
        .get(`${url}/teachersNot`)
        .then((res) => {
          this.teachersNot = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeacherBySchool() {
      axios
        .get(`${url}/teachersForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teachers = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    registerTeacher() {
      this.$refs.form.validate();
      axios
        .post(`${url}/teachersForSchool`, this.formTeacher, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          const data = {
            teacherId: res.data.success.id,
          };
          axios
            .post(`${url}/teacherInscriptionsForSchool`, data, {
              headers: {
                authorization: token,
              },
            })
            .then(() => {
              this.closeRegister();
              this.getTeacherBySchool();
            });
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateTeacher(id) {
      axios
        .put(`${url}/teachers/${id}`, this.formTeacher, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getTeacherBySchool();
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/teacherInscriptions/${this.formTeacher.teacherId}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getTeacherBySchool();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.formTeacher = {};
      this.editMode = false;
      this.$refs.form.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formTeacher = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.formTeacher = Object.assign({}, item);
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formTeacher = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },
    viewAppliedClasses(teacher) {
      axios
        .get(`${url}/appliedClassesByTeacher/${teacher.id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.selectedTeacherClasses = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
      this.appliedClassesDialog = true;
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },
  },
  created() {
    this.getTeacherBySchool();
  },
};
</script>
