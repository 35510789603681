<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="pink" class="text-h4">mdi-account-group</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('school.school_titleturma') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        v-model="dialog"
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="darkpink" rounded dark v-bind="attrs" v-on="on">
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_criaturma') }}
          </v-btn>
          <v-btn color="darkpink" rounded dark v-on="on" style="left: -1%" @click="MenuAula(1)">
            <v-icon left> mdi-school </v-icon>
            {{ $t('botoes.botao_veraula') }}
          </v-btn>
          <v-btn color="darkpink" rounded dark v-on="on" style="left: -2%" @click="MenuAula(2)">
            <v-icon left> mdi-account-school </v-icon>
            {{ $t('botoes.botao_veraluno') }}
          </v-btn>
          <v-btn color="darkpink" rounded dark v-on="on" style="left: -3%" @click="MenuAula(3)">
            <v-icon left> mdi-calendar-star </v-icon>
            {{ $t('botoes.botao_verevento') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('school.school_novaturma') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="teamsForm">
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                      color="blue"
                      v-model="formTeam.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nometurma')"
                      required
                    >
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      item-color="darkblue"
                      v-model="formTeam.courseId"
                      :items="courses"
                      :rules="[rules.option]"
                      :label="$t('label.label_curso')"
                      no-data-text="Selecione a Escola"
                      item-value="courseId"
                      item-text="Course.name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      item-color="darkblue"
                      v-model="formTeam.teacherId"
                      :items="teachers"
                      no-data-text="Selecione a Escola"
                      :label="$t('label.label_professor')"
                      :rules="[rules.option]"
                      item-value="id"
                      item-text="name"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" :sm="editMode ? 6 : 6">
                    <v-menu
                      ref="menuIniDate"
                      v-model="menuIniDate"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="formTeam.InicDate"
                          :label="$t('label.label_datas.inicial')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="formTeam.InicDate"
                        :active-picker.sync="activePicker"
                        min="1950-01-01"
                        @change="saveDateInc"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" :sm="editMode ? 6 : 6">
                    <v-menu
                      ref="menuFinDate"
                      v-model="menuFinDate"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="formTeam.FinalDate"
                          :label="$t('label.label_datas.final')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="formTeam.FinalDate"
                        :active-picker.sync="activePicker"
                        min="1950-01-01"
                        @change="saveDateFin"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>{{ $t('regras.regra_obrigatorio') }}</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn color="darkpink" rounded text @click="saveBtn()">
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <!-- <v-row class="ma-4">
      <v-col
        cols="12"
        sm="6"
        md="4"
        lg="3"
        v-for="team in Teams"
        :key="team.id"
      >
        <v-card
          class="rounded-xl d-flex flex-column justify-space-between pointer"
          @click="getDetails(team)"
          elevation="4"
          height="300"
        >
          <v-img
            :src="team.Course.thumbnail"
            class="rounded-t-xl"
            height="200"
          ></v-img>

          <v-card-actions class="darkblue pa-4 white--text">
            <v-row>
              <v-col cols="12">
                <div
                  class="font-weight-bold"
                  :style="
                    $vuetify.breakpoint.xsOnly
                      ? 'font-size: 1rem'
                      : 'font-size: 1.2rem'
                  "
                >
                  {{ team.name }}
                </div>
              </v-col>
            </v-row>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row> -->

      <v-data-table
        :headers="headers"
        :items="Teams"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            rounded
            outlined
            small
            @click="getDetails(item)"
            color="darkblue"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1"> mdi-information</v-icon>
            {{ $t('botoes.botao_detalhe') }}
          </v-btn>
        </template>
      </v-data-table>
    <v-dialog
      v-model="teamDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '60%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-tabs v-model="classTab" background-color="darkblue" grow dark>
          <v-tabs-slider></v-tabs-slider>
          <v-tab href="#1"> {{ $t('teams.team_tab.tab1') }} </v-tab>
          <v-tab href="#2"> {{ $t('teams.team_tab.tab2') }} </v-tab>
          <v-tab href="#3"> {{ $t('teams.team_tab.tab3') }} </v-tab>
          <v-tab href="#4">
            {{ $t('teams.team_tab.tab4') }}l&nbsp;<span v-if="$vuetify.breakpoint.lgAndUp"> {{ $t('teams.team_tab.tab5') }}</span>
          </v-tab>
        </v-tabs>
        <v-btn
          rounded
          outlined
          @click="teamDialog = false"
          color='white'
          >
          <v-icon class="mr-1" color="error">mdi-close-circle</v-icon>
       </v-btn>
        <v-tabs-items :value="classTab">
          <v-tab-item value="1">
            <v-card-actions class="mt-2 d-flex justify-end">
              <v-btn
                rounded
                outlined
                small
                @click="editItem(teamDetails.all)"
                color="darkblue"
                class="px-2 mx-1"
              >
                <v-icon small class="mr-1">mdi-pencil</v-icon>
                {{ $t('botoes.botao_edita') }}
              </v-btn>
              <v-btn
                rounded
                outlined
                small
                @click="deleteItem(teamDetails.all)"
                color="error"
                class="px-2 mx-1"
              >
                <v-icon small class="mr-1">mdi-delete</v-icon>
                {{ $t('botoes.botao_excluir') }}
              </v-btn>
            </v-card-actions>
            <v-card-text
              class="px-4 pb-6 pb-sm-8 pb-md-10 pb-lg-16 d-flex flex-row"
            >
              <div class="text-center">
                <v-avatar
                  :size="$vuetify.breakpoint.mdAndUp ? 260 : 200"
                  tile
                  class="rounded-xl mx-auto"
                >
                  <v-img :src="teamDetails.thumb" />
                </v-avatar>
              </div>

              <div style="font-size: 1.2rem" class="mt-4 ml-8">
                <div style="font-size: 1.6rem" class="font-weight-black">
                  {{ teamDetails.team }}
                </div>

                <div class="mt-4">
                  <strong>{{ $t('teams.team_detalhes.detalhes1') }}</strong> {{ teamDetails.course }}
                </div>
                <div>
                  <strong>{{ $t('teams.team_detalhes.detalhes2') }}</strong> {{ teamDetails.teacher }}
                </div>
                <div>
                  <strong>{{ $t('teams.team_detalhes.detalhes4') }} </strong> {{ teamDetails.DateInicial}}
                </div>
                <div>
                  <strong>{{ $t('teams.team_detalhes.detalhes5') }}</strong> {{ teamDetails.DateFinal}}
                </div>
              </div>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="2">
            <v-card-text class="my-4">
              <v-alert
                :color="colorLookLight(index)"
                text
                dense
                outlined
                v-for="(data, index) in classes"
                :key="data.id"
                :icon="$vuetify.breakpoint.xsOnly ? false : 'mdi-bookshelf'"
                :prominent="!$vuetify.breakpoint.xsOnly"
                class="my-3"
              >
                <v-row align="center" no-gutters>
                  <v-col
                    cols="12"
                    sm="8"
                    lg="9"
                    class="grow font-weight-bold black--text"
                  >
                    <div style="font-size: 1.1rem">
                      {{ data.title }}
                    </div>
                    <div style="font-size: 0.9rem">
                      {{ data.project }}
                    </div>
                    <div v-if="data.DateApplied" style="font-size: 0.9rem">
                      <div v-if="data.applied">
                        {{ $t('teams.team_aulas') }} {{data.DateApplied.split('-').reverse().join('/')}}
                      </div>   
                    </div>
                  </v-col>
                  <v-col cols="12" sm="4" lg="3" class="pa-0 ma-0 black--text">
                    <div sm="4" lg="3">
                      <v-icon v-if="!data.applied" left color="red">
                        mdi-close
                      </v-icon>
                      <v-icon v-if="data.applied" left color="green">
                        mdi-check
                      </v-icon>
                      <span
                        v-text="data.applied ? 'Aula Aplicada' : 'Não Aplicada'"
                      />
                    </div>
                    <div style="display: flex;
                        flex-direction: column;
                        justify-content: space-evenly;
                        flex-wrap: wrap;">
                      <v-btn
                        :color="data.applied ? colorLookDark(index) : 'defaultColor'"
                        @click="openPhotosUploadDialog(data)"
                        class="ma-0 mt-1"
                        :dense="$vuetify.breakpoint.xsOnly"
                        :disabled="!data.applied"
                      >
                        <v-icon left>
                          mdi-file-upload
                        </v-icon>
                        {{ $t('teams.team_foto') }}
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-alert>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="3">
            <v-card-text v-if="students.length !== 0" class="my-4">
              <v-alert
                :color="colorLookLight(index)"
                text
                outlined
                v-for="(student, index) in students"
                :key="index"
                :icon="$vuetify.breakpoint.xsOnly ? false : 'mdi-school'"
                :prominent="!$vuetify.breakpoint.xsOnly"
                class="my-3"
              >
                <v-row align="center" no-gutters>
                  <v-col
                    cols="12"
                    sm="8"
                    lg="9"
                    class="grow font-weight-bold black--text"
                  >
                    <div style="font-size: 1.2rem">
                      {{ student.name }}
                    </div>
                    <div style="font-size: 0.9rem" class="d-flex flex-column">
                      <span>{{ student.user }}</span>
                      <span>{{ student.email }}</span>
                      <span>{{ student.phone }}</span>
                    </div>
                  </v-col>
                </v-row>
              </v-alert>
            </v-card-text>
            <v-card-text v-if="students.length === 0" class="pa-4 pb-8">
              <v-img
                src="../../assets/MoonBalloon.svg"
                max-width="320px"
                class="mx-auto"
              />
              <div
                class="text-center text-h6 text-sm-h5 font-weight-bold mt-2"
                style="color: #491670"
              >
               {{ $t('teams.team_semaluno') }}
              </div>
            </v-card-text>
          </v-tab-item>
          <v-tab-item value="4">
            <v-card-text style="height: 75vh; overflow-y: scroll">
              <v-row no-gutters>
                <v-col cols="12" md="6" v-for="(fil, ind) in files" :key="ind">
                  <v-card flat class="ma-2 white--text rounded-sm" height="100">
                    <v-alert
                      :icon="$vuetify.breakpoint.xsOnly ? false : fil.icon"
                      :prominent="!$vuetify.breakpoint.xsOnly"
                      height="100%"
                      class="
                        d-flex
                        justify-space-between
                        align-center
                        font-weight-medium
                      "
                      style="font-size: 1.2rem"
                      :color="colorLookLight(ind + 1)"
                      text
                      outlined
                    >
                      <div
                        v-if="fil.icon !== 'mdi-play-box'"
                        class="d-flex flex-column linkText"
                        @click="openURL(fil.uri)"
                      >
                        <span class="black--text font-weight-bold">
                          {{ fil.name }}
                        </span>
                        <span
                          v-if="fil.size"
                          v-text="getByteSize(fil.size)"
                          class="black--text text--secondary font-weight-medium"
                          style="font-size: 0.9rem"
                        />
                      </div>
                      <div
                        v-if="fil.icon === 'mdi-play-box'"
                        class="d-flex flex-column linkText"
                        @click="openVideo(fil)"
                      >
                        <span class="black--text font-weight-bold">
                          {{ fil.name }}
                        </span>
                        <span
                          v-if="fil.size"
                          v-text="getByteSize(fil.size)"
                          class="black--text text--secondary font-weight-medium"
                          style="font-size: 0.9rem"
                        />
                      </div>
                      <span>
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              v-bind="attrs"
                              v-on="on"
                              color="error"
                              icon
                              absolute
                              top
                              right
                              @dblclick="deleteFile(fil.id, teamDetails.id)"
                            >
                              <v-icon color="error">mdi-close</v-icon>
                            </v-btn>
                          </template>
                          <span>
                            {{ $t('alerta.alerta_excluir.excluir2') }}
                          </span>
                        </v-tooltip>
                      </span>
                    </v-alert>
                  </v-card>
                </v-col>
                <v-col v-if="files.length === 0" cols="12" class="mx-auto">
                  <v-img
                    src="../../assets/NotebookAstronaut.svg"
                    max-width="25vw"
                    class="mx-auto"
                  />
                  <div
                    class="text-center text-h6 text-md-h5 font-weight-bold mt-2"
                    style="color: #491670"
                  >
                    <span> {{ $t('alerta.alerta_material') }} </span>
                  </div>
                </v-col>
              </v-row>
              <v-btn
                absolute
                style="bottom: 16px; right: 16px"
                color="darkpink"
                dark
                fab
                @click="openNewFile()"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </v-card-text>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm()"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="confirmDeletePhoto" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >{{ $t('alerta.alerta_excluir.excluir3') }}</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="confirmDeletePhoto = false"
            >{{ $t('botoes.botao_cancelar') }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="DeletePhotosFileChange()"
            >{{ $t('botoes.botao_confirma') }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>


    <v-dialog v-model="photosDialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          {{ $t('teams.team_upload.label') }}
          <v-spacer></v-spacer>
          <v-btn icon @click="photosDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>
          <v-file-input
            v-model="selectedFiles"
            multiple
            show-size
            @change="handleAppliedClassPhotosFileChange"
            :label="$t('label.label_enviarfoto')"
            :placeholder="$t('teams.team_upload.placeholder')"
          ></v-file-input>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="uploadAppliedClassPhotos">Enviar</v-btn>
          <v-spacer></v-spacer>
          <div class="loaded-photos-container">
            <v-subheader>{{ $t('teams.team_fotos') }}</v-subheader>
            <!-- Carrossel de fotos começa aqui -->
            <v-carousel
              cycle
              height="400"
              hide-delimiter-background
              show-arrows-on-hover
            >
              <v-carousel-item
                v-for="(photo, index) in photosLoaded"
                :key="index"
              >
                <v-img
                  :src="photo"
                  height="100%"
                > 
                  <v-btn color="red" icon @click="ConfirmDeletePhotosFileChange(photo)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>                
                </v-img>
              </v-carousel-item>
            </v-carousel>
            <!-- Carrossel de fotos termina aqui -->
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="photosDialog = false">{{ $t('botoes.botao_fecha') }}</v-btn>
        </v-card-actions>
      </v-card>

      
    </v-dialog>

    <v-dialog
      v-model="newFile"
      :width="$vuetify.breakpoint.mdAndUp ? '55%' : '90%'"
      persistent
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text"> {{ $t('botoes.botao_arquivo') }} </v-card-title>
        <v-card-text class="pa-4 pa-md-6">
          <v-form ref="newFileForm">
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="file.name"
                  :rules="[rules.required]"
                  :label="$t('label.label_nome')"
                  color="darkblue"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  :label="$t('label.label_descricao')"
                  v-model="file.description"
                  color="darkblue"
                ></v-textarea>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  :items="selectType"
                  color="darkblue"
                  :label="$t('label.label_tipo')"
                  item-text="value"
                  item-value="id"
                  v-model="type"
                  item-color="darkblue"
                />
              </v-col>

              <v-col cols="12" sm="6" v-if="type == 1">
                <v-text-field
                  :rules="[rules.required]"
                  v-model="file.link"
                  :label="$t('label.label_link')"
                  color="darkblue"
                  prepend-icon="mdi-link"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" v-if="type == 2">
                <template>
                  <v-file-input
                    :rules="[rules.required]"
                    :label="$t('label.label_arquivos.arquivo')"
                    color="darkblue"
                    v-model="file.file"
                  ></v-file-input>
                </template>
              </v-col>
              <v-col cols="12" sm="6" v-if="type == 3">
                <template>
                  <v-file-input
                    :rules="[rules.required]"
                    :label="$t('label.label_video')"
                    color="darkblue"
                    accept="video/*"
                    persistent-hint
                    :hint="$t('teams.team_hint')"
                    v-model="file.video"
                  ></v-file-input>
                </template>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions class="d-flex align-center justify-end">
          <v-btn
            rounded
            text
            color="darkpink"
            class="px-4"
            @click="closeNewFile()"
            :disabled="loadingFile"
          >
            {{ $t('botoes.botao_cancelar') }}
          </v-btn>
          <v-btn
            rounded
            dark
            color="darkpink"
            class="px-4"
            @click="saveNewFile(teamDetails.all, file)"
            :loading="loadingFile"
          >
            {{ $t('botoes.botao_salva') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="videoDialog"
      :width="$vuetify.breakpoint.mdAndUp ? '55%' : '90%'"
    >
      <v-card class="rounded-lg">
        <v-card-title class="darkblue white--text">
          <span class="font-weight-bold">
            {{ videoData.name }}
          </span>
          <span
            v-text="`(${getByteSize(videoData.size)})`"
            class="font-weight-medium ml-2"
            style="font-size: 0.9rem"
          />
          <v-spacer></v-spacer>
          <v-icon @click="videoDialog = false" color="white">mdi-close</v-icon>
        </v-card-title>
        <v-card-text class="pa-4">
          <div class="mb-4">
            <span class="black--text"> {{ $t('homework.homework_detalhes.detalhes3') }} </span>
            {{ videoData.description }}
          </div>
          <div class="d-flex justify-center align-center">
            <vimeo-player
              ref="player"
              :video-url="`https://player.vimeo.com/video/${videoData.uri}`"
              @ready="onReady"
            />
          </div>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_title') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('alerta.alerta_dataAp') }}
        </v-btn>
      </template>
    </v-snackbar>
    <v-snackbar
      v-model="errorSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="error"
      outlined
      text
    >
      <v-icon large color="error" class="mr-2">mdi-close-circle </v-icon>
      <span class="subtitle-1 black--text">
       {{ $t('alerta.alerta_erro') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="errorSnackbar = null"
          rounded
          color="error"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
const authorizations = { headers: { authorization: token } };
export default {
  data() {
    return {
      menu: [
        {
          title: this.$t('admin.admin_menu.menu11'),
        },
      ],
      selectType: [
        { id: 1, value: this.$t('teams.team_select.select1')},
        { id: 2, value: this.$t('teams.team_select.select2')},
        { id: 3, value: this.$t('teams.team_select.select3')},
      ],
      newFile: false,
      file: [],
      files: [],
      menuIniDate: false,
      menuFinDate: false,
      activePicker: null,
      dateFormattedInic: null,
      dateFormattedFinal: null,      
      loadingFile: false,
      videoDialog: false,
      videoData: [],
      dialog: null,
      formTeam: {},
      search: "",
      headers: [
        { text: this.$t('admin.admin_menu.menu13'), value: "name" },
        { text: this.$t('admin.admin_menu.menu2'), value: "Course.name" },
        { text: this.$t('admin.admin_menu.menu10'), value: "teacher.name" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      Teams: [],
      editedIndex: -1,
      dialogDelete: null,
      errorSnackbar: false,
      successSnackbar: false,
      photosDialog: false,    
      selectedFiles: [],
      photosLoaded: [],
      confirmDeletePhoto: false,  
      deletephoto: '',
      editMode: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        none: true,
      },
      schools: [],
      courses: [],
      teachers: [],
      addStudent: false,
      type: 1,
      teamSingular: "",
      students: [],
      teamDetails: { id: "", thumb: "", team: "", course: "", teacher: "" },
      teamDialog: false,
      classTab: 1,
      classes: [],
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date() {
      this.dateFormattedInic = this.formatDate(this.formTeam.InicDate);
      this.dateFormattedFinal = this.formatDate(this.formTeam.FinalDate);
    },
  },  
  methods: {
    saveDateInc(date) {
      this.$refs.menuIniDate.save(date);
      
    },    
    saveDateFin(date) {   
      this.$refs.menuFinDate.save(date);
    },        
    getTeamStudent(team) {
      this.teamSingular = team.name;
      localStorage.setItem("teamSingular", team.name);
      this.$emit("message", this.$t('admin.admin_menu.menu11'));
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu11');
    },
    getCoursesTeachers() {
      axios
        .get(`${url}/courseInscriptionsForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.courses = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
      axios
        .get(`${url}/teachersForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teachers = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },  
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeam() {
      axios
        .get(`${url}/TeamsForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Teams = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    MenuAula(id) {
      switch (id) {
        case 1:
          this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu3')
          break;          
        case 2:
          this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu11')
          
          break;        
        case 3:
          this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu7')
          break;                
      }
      
    },
    async saveBtn() {
      let validation = this.$refs.teamsForm.validate();

      if (validation) {
        if (!this.editMode) {
          try {
            await this.registerTeam();
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        if (this.editMode) {
          try {
            await this.updateTeam(this.formTeam.id);
            this.editMode = false;
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
          } catch {
            this.errorSnackbar = true;
          }
        }
        this.$refs.teamsForm.reset();
        this.dialog = false;
      }
    },
    registerTeam() {
      axios
        .post(`${url}/TeamsForSchool`, this.formTeam, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getTeam();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    updateTeam(id) {
      axios
        .put(`${url}/teams/${id}`, this.formTeam, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getTeam();
          this.teamDialog = false;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    handleAppliedClassPhotosFileChange() {
      // Este método é chamado quando os arquivos são selecionados ou alterados
      // Você pode implementar lógica adicional aqui, se necessário
    },
    openPhotosUploadDialog(data) {
      console.log(data);
      this.classId = data.id;
      this.photosDialog = true;
      this.fetchLoadedPhotos();
    },
    async fetchLoadedPhotos() {
      try {
        const response = await axios.get(
          `${url}/appliedClasses/loadPhotos/${this.classId}/${this.teamDetails.id}`,
          authorizations
        ); // Ajuste o URL conforme necessário
        console.log(response);
        this.photosLoaded = response.data.urls; // Ajuste conforme a estrutura da sua resposta
      } catch (error) {
        console.error(error);
      }
    },
    ConfirmDeletePhotosFileChange(data) {
      this.deletephoto = data.split('https://newmoodle.s3.amazonaws.com/')
      this.confirmDeletePhoto = true
    },
    DeletePhotosFileChange() {
      console.log(this.deletephoto[1])
      axios.delete(
          `${url}/DeleteClasses/${this.deletephoto[1]}`,
          authorizations
        ); // Ajuste o URL conforme necessário 
      setTimeout(() => {
          this.fetchLoadedPhotos();
          this.confirmDeletePhoto = false
          this.successSnackbar = true;
      }, 500);
    },    

    async uploadAppliedClassPhotos() {
      let formData = new FormData();

      // Se múltiplos arquivos forem permitidos, você precisará iterar sobre eles
      this.selectedFiles.forEach((file) => {
        formData.append("files", file); // Use "files" se estiver enviando múltiplos arquivos
      });

      formData.append("classId", String(this.classId));
      formData.append("teamId", String(this.teamDetails.id));

      try {
        const response = await axios.post(`${url}/appliedClasses/files`, formData, {});
        let link = response.data.url;
        console.log(link);
        this.successSnackbar = true;
        this.selectedFiles = [];
        this.fetchLoadedPhotos();
      } catch (error) {
        console.error(error);
      }
    },

    deleteItemConfirm() {
      axios
        .delete(`${url}/teams/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getTeam();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.editMode = false;
      this.$refs.teamsForm.reset();
      this.formTeam.InicDate = ''
      this.formTeam.FinalDate = ''
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formTeam = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;

      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formTeam = Object.assign({}, item);
      this.getCoursesTeachers();
      this.dialog = true;
      this.editMode = true;
    },
    getDetails(data) {
      console.log(data)
      this.teamDetails.id = data.id;
      this.teamDetails.thumb = data.Course.thumbnail;
      this.teamDetails.team = data.name;
      this.teamDetails.course = data.Course.name;
      this.teamDetails.teacher = data.teacher.name;
      this.teamDetails.DateInicial = data.InicDate.split('-').reverse().join('/') 
      this.teamDetails.DateFinal = data.FinalDate.split('-').reverse().join('/') 
      this.teamDetails.all = data;


      const body = {
        teacherId: data.teacherId,
        teamId: data.id,
        schoolId: data.schoolId,
        courseId: data.courseId,
      };
      console.log(body)
      this.getClassDetails(body);
      this.getStudents(data.id);
      this.getFiles(body.teamId)

      this.classTab = 1;
      this.teamDialog = true;
    },
    async getFiles(teamId) {
      let files = await axios.get(
        `${url}/teacherTeamFiles/student/${teamId}`, authorizations);
      //console.log(teamId)
      //console.log(files)
      this.files = files.data;
      this.newFile = false;
      this.loadingFile = false;
      this.getType();
    },
    async saveNewFile(details, data) {
      let validation = this.$refs.newFileForm.validate();

      if (validation) {
        this.loadingFile = true;

        try {
          if (this.type === 1) {
            let body = {
              name: data.name,
              type: ".link",
              description: data.description,
              uri: data.link,
              schoolId: details.schoolId,
              teacherId: details.teacherId,
              teamId: details.id,
            };

            this.saveFile(body);
          }

          if (this.type === 2) {
            let res = await this.registerFile(data.file);
           // console.log("Res", res);

            let body = {
              name: data.name,
              type: res.type,
              size: res.size,
              description: data.description,
              uri: res.uri,
              schoolId: details.schoolId,
              teacherId: details.teacherId,
              teamId: details.id,
            };

            this.saveFile(body);
          }

          if (this.type === 3) {
            let res = await this.registerVideo(data.video);
            //console.log("Res", res);

            let body = {
              name: data.name,
              type: res.type,
              size: res.size,
              description: data.description,
              uri: res.uri,
              schoolId: details.schoolId,
              teacherId: details.teacherId,
              teamId: details.id,
            };

            this.saveFile(body);
          }
        } catch (error) {
          this.loadingFile = false;
          this.errorSnackbar = true;
          console.error(error);
        }
      }
    },
    async registerVideo(video) {
      let formData = new FormData();
      formData.append("video", video, video.name);

      let response = await axios.post(`${url}/upload-video/vimeo`, formData);
      //console.log(response);
      let link = response.data.videoId;

      let elem = video.name.split(".");
      let type = "." + elem[1];

      let fileData = {
        type: type,
        size: video.size,
        uri: link,
      };

      return fileData;
    },
    onReady() {
      this.playerReady = true;
    },
    async registerFile(file) {
      let formData = new FormData();
      formData.append("file", file, file.name);

      let response = await axios.post(`${url}/files/upload`, formData);
      let link = response.data.url;

      let elem = file.name.split(".");
      let type = "." + elem[1];

      let fileData = {
        type: type,
        size: file.size,
        uri: link,
      };

      return fileData;
    },
    async saveFile(body) {
      await axios.post(`${url}/teacherTeamFiles`, body, {
          headers: {
            authorization: token,
          },
        });
      this.successSnackbar = true;
      this.$refs.newFileForm.reset();
      this.getFiles(body.teamId);
      this.newFile = false;
      this.loadingFile = false;
    },
    deleteFile(id, teamId) {
      axios.delete(`${url}/teacherTeamFiles/${id}`, 
      {
          headers: {
            authorization: token,
          },
        });

      this.getFiles(teamId);
    },
    openNewFile() {
      this.newFile = true;
    },
    closeNewFile() {
      this.$refs.newFileForm.reset();
      this.newFile = false;
    },
    getByteSize(byte_value) {
      let converted_value = null;
      let data_type = "";
      if (byte_value < 1024) {
        data_type = "bytes";
        converted_value = byte_value;
      } else if (byte_value < 1024 * 1024) {
        data_type = "KB";
        converted_value = (byte_value / 1024).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024) {
        data_type = "MB";
        converted_value = (byte_value / (1024 * 1024)).toFixed(2);
      } else if (byte_value < 1024 * 1024 * 1024 * 1024) {
        data_type = "GB";
        converted_value = (byte_value / (1024 * 1024 * 1024)).toFixed(2);
      } else {
        data_type = "TB";
        converted_value = (byte_value / (1024 * 1024 * 1024 * 1024)).toFixed(2);
      }
      let response = converted_value + " " + data_type;
      return response;
    },
    getType() {
      for (let index = 0; index < this.files.length; index++) {
        const element = this.files[index];

        switch (element.type) {
          case ".pdf":
            element.icon = "mdi-file-pdf-box";
            break;
          case ".docx":
          case ".docm":
          case ".dotx":
          case ".dotm":
          case ".txt":
            element.icon = "mdi-file-word-box";
            break;
          case ".xlsx":
          case ".xlsm":
          case ".xltx":
          case ".xltm":
          case ".xlsb":
          case ".xlam":
            element.icon = "mdi-file-excel-box";
            break;
          case ".pptx":
          case ".pptm":
          case ".potx":
          case ".potm":
          case ".ppam":
          case ".ppsx":
          case ".ppsm":
          case ".sldx":
          case ".sldm":
          case ".thmx":
            element.icon = "mdi-file-powerpoint-box";
            break;
          case ".mp4":
          case ".mov":
          case ".wmv":
          case ".avi":
          case ".avchd":
          case ".flv":
          case ".f4v ":
          case ".swf":
          case ".mkv":
          case ".webm":
          case ".html5":
          case ".mpeg-2":
            element.icon = "mdi-play-box";
            break;
          case ".png":
          case ".jpg":
          case ".jpeg":
          case ".gif":
          case ".bmp":
          case ".psd":
          case ".tiff":
          case ".svg":
          case ".raw":
          case ".webp":
            element.icon = "mdi-image";
            break;
          case ".zip":
          case ".arj":
          case ".cab":
          case ".rar":
          case ".tar":
          case ".z":
          case ".gz":
          case ".taz ":
          case ".tgz":
          case ".gzip":
            element.icon = "mdi-zip-box";
            break;
          case ".link":
            element.icon = "mdi-link";
            break;
          default:
            element.icon = "mdi-file";
            break;
        }
      }
    },

    openURL(url) {
      window.open(url);
    },
    openVideo(link) {
      this.videoDialog = true;

      this.videoData = link;
    },
    
    async getStudents(id) {
      let res = await axios.get(`${url}/student/team/${id}`);

      this.students = res.data.result;
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },
    getClassDetails(body) {
      axios
        .post(`${url}/appliedClasses/applied`, body, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.classes = res.data;
          console.log(this.classes)

          for (let index = 0; index < this.classes.length; index++) {
            const element = this.classes[index];
            if (!element.applied) this.applyStatus = false;

            if (!element.visible) this.visibleStatus = false;
          }
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
  created() {
    this.getTeam();
    this.getSchools();
    this.getCoursesTeachers();
  },
};
</script>
