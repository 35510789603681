<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="blue" class="text-h4">mdi-account-school</v-icon>
      <v-toolbar-title
        style="letter-spacing: 0.5rem"
        class="ml-3 font-weight-bold"
        >{{ $t('student.student_title') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-dialog v-model="listAulasOn" max-width="800px">
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('school.school_qtd.aulasaplica') }}</span>
          </v-card-title>
          <v-card-text>
            <div
              class="text-center text-h6 black--text"
              v-if="semAulasP == true"
            >
              <v-img
                max-width="200px"
                class="mx-auto"
                src="../../assets/OnPencil.svg"
              />
              <span class="mt-6">
                {{ $t('school.school_nenhumaplicado') }}
              </span>
            </div>
            <v-container v-if="resuu == true">
              <v-data-table
                :headers="headersDasAulas"
                :items="appliedClassItem"
                :footer-props="{
                  'items-per-page-options': [20, 45, 60, 100, -1],
                  'items-per-page-all-text': $t('events.events_headers.headers1'),
                  'items-per-page-text': $t('student.student_paginas'),
                  'show-first-last-page': true,
                }"
              >
                <template v-slot:[`item.actions`]="{ item }">
                  <v-btn
                    rounded
                    outlined
                    small
                    @click="atividade(item)"
                    color="darkpink"
                    class="px-2 mx-1"
                  >
                    <v-icon small class="mr-1">mdi-book</v-icon>
                    {{ $t('admin.admin_menu.menu19') }}
                  </v-btn>
                </template>
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeAulasContainer()">
              {{ $t('botoes.botao_sair') }}
            </v-btn>
          </v-card-actions>
        </v-card>
        <!-- <v-card class="rounded-lg">
          <v-card-title class="black white--text">
            <span class="text-h5">Aulas extras</span>
          </v-card-title>
          <v-card-text>
            <v-container> aqui vão as aulas extras </v-container>
          </v-card-text>
         
        </v-card> -->
      </v-dialog>

      <v-dialog v-model="listOn" max-width="800px">
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('admin.admin_menu.menu19') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="atividadeHeader"
                :items="atividadeItems"
                :footer-props="{
                  'items-per-page-options': [20, 45, 60, 100, -1],
                  'items-per-page-all-text': $t('events.events_headers.headers1'),
                  'items-per-page-text': $t('student.student_paginas'),
                  'show-first-last-page': true,
                }"
              >
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeAtividade()">
              {{ $t('botoes.botao_sair') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="darkpink"
            rounded
            dark
            v-bind="attrs"
            v-on="on"
            @click="formStudent = {}"
          >
            <v-icon left> mdi-plus-thick </v-icon>
            {{ $t('botoes.botao_criaraluno') }}
          </v-btn>
          <v-btn color="darkpink" rounded dark v-on="on" style="left: -1%" @click="MenuTurmas()">
            <v-icon left> mdi-account-group </v-icon>
            {{ $t('school.school_turmas') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('botoes.botao_novoaluno') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.name"
                      :rules="[rules.required]"
                      :label="$t('label.label_nome')"
                      required
                      @change="doUser(formStudent.name)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.user"
                      :rules="[rules.required, rules.user]"
                      :label="$t('label.label_usuario')"
                      :loading="userLoad"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      :label="$t('label.label_email.email')"
                      :rules="[rules.email]"
                      v-model="formStudent.email"
                      required
                    ></v-text-field>
                  </v-col>

                  <!-- <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      v-model="formStudent.grade"
                      :items="grades"
                      :rules="[rules.required]"
                      label="Grade*"
                      required
                    ></v-select>
                  </v-col> -->
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      v-model="formStudent.teamId"
                      :items="turmas"
                      :rules="[rules.required]"
                      :label="$t('label.label_turma')"
                      item-text="name"
                      item-value="id"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" :sm="editMode ? 12 : 6">
                    <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="computedDateFormatted"
                          :label="$t('label.label_datanascimento')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          @blur="
                            formStudent.birthday = parseDate(
                              computedDateFormatted
                            )
                          "
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="formStudent.birthday"
                        :active-picker.sync="activePicker"
                        :max="
                          new Date(
                            Date.now() - new Date().getTimezoneOffset() * 60000
                          )
                            .toISOString()
                            .substr(0, 10)
                        "
                        min="1950-01-01"
                        @change="save"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      color="blue"
                      v-model="formStudent.password"
                      :rules="[rules.required, rules.length(4)]"
                      :label="$t('label.label_senha.label_senhaob')"
                      type="password"
                      v-if="!editMode"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>{{ $t('regras.regra_obrigatorio') }}*</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn
              color="darkpink"
              rounded
              text
              @click="
                editMode ? updateStudent(formStudent.id) : registerStudent()
              "
            >
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_excluir.excluir1') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm"
          >
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="elevation-0 mt-1">
      <v-card-title>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('admin.admin_menu.menu11') }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          color="blue"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$vuetify.breakpoint.smAndUp ? $t('label.label_pesquisa') : ''"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Students"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            rounded
            outlined
            small
            @click="onAulasList(item)"
            color="darkpink"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-television-play</v-icon>
            {{ $t('admin.admin_menu.menu3') }}
          </v-btn>
          <v-btn
            rounded
            outlined
            small
            @click="editItem(item)"
            color="darkblue"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-pencil</v-icon>
            {{ $t('botoes.botao_edita') }}
          </v-btn>
          <v-btn
            rounded
            outlined
            small
            @click="deleteItem(item)"
            color="error"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-delete</v-icon>
            {{ $t('botoes.botao_excluir') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      activePicker: null,
      menu: false,
      dateFormatted: null,
      resuu: false,
      semAulasP: false,
      listAulasOn: null,
      listOn: null,
      dialog: null,
      formStudent: {},
      search: "",
      headers: [
        { text: this.$t('login.login_usuario.usuario1'), value: "name" },
        { text: this.$t('certificado.cert_headers.headers2'), value: "user" },
        { text: this.$t('admin.admin_menu.menu13'), value: "team.name" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      headersDasAulas: [
        { text: this.$t('admin.admin_menu.menu18'), value: "title" },
        { text: this.$t('admin.admin_menu.menu2'), value: "course" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      atividadeHeader: [
        { text: this.$t('school.school_headeratividade.headers1'), value: "content" },
        { text: this.$t('school.school_headeratividade.headers2'), value: "correct" },
      ],
      atividadeItems: [],
      appliedClassItem: [],
      Students: [],
      editedIndex: -1,
      dialogDelete: null,
      editMode: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        none: true,
      },
      nameSchool: null,
      schoolName: [],
      userExisting: false,
      userLoad: false,
      schools: [],
      turmas: [],
      grades: [
        "1° Infantil",
        "2° Infantil",
        "3° Infantil",
        "4° Infantil",
        "1° Fundamental 1",
        "2° Fundamental 1",
        "3° Fundamental 1",
        "4° Fundamental 1",
        "5° Fundamental 1",
        "6° Fundamental 2",
        "7° Fundamental 2",
        "8° Fundamental 2",
        "9° Fundamental 2",
        "1° Médio",
        "2° Médio",
        "3° Médio",
      ],
      // IDdaEscola: 8  //dps pegar o id correto do localStorage
    };
  },
  computed: {
    computedDateFormatted() {
      return this.formatDate(this.formStudent.birthday);
    },
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date() {
      this.dateFormatted = this.formatDate(this.formStudent.birthday);
    },
  },
  methods: {
    MenuTurmas() {
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu13')
    },    
    async doUser(name) {
      name = await this.removeAccents(name);
      this.userLoad = true;
      const array = await name.split(" ");
      let user = array[0] + "." + array[array.length - 1];
      let result = await this.verifyUser(user);
      let i = 1;
      let tryUser = user;
      while (result) {
        tryUser = user + `${i}`;
        result = await this.verifyUser(tryUser);
        i++;
      }
      setTimeout(() => {
        user = tryUser;
        this.formStudent.user = user.toLowerCase();
        this.userLoad = false;
      }, 1000);
    },
    save(date) {
      this.$refs.menu.save(date);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    async verifyUser(res) {
      let response = await axios.get(`${url}/students/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });

      if (response.data !== null) {
        // this.userExisting = true;
        return true;
      } else {
        // this.userExisting = false;
        return false;
      }
    },
    checkStudentLink() {
      for (let i = 0; i < this.Students.length; i++) {
        axios
          .get(`${url}/studentLinkTeams/?student=${this.Students[i].id}`, {
            headers: {
              authorization: token,
            },
          })
          .then((res) => {
            if (res.data.length > 0) {
              this.Students[i].checked = true;
            } else {
              this.Students[i].checked = false;
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    getTeamsByGrade(grade) {
      axios
        .get(`${url}/teamByGradeSchool/?grade=${grade}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.turmas = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStudent() {
      axios
        .get(`${url}/studentsBySchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Students = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeamsBySchool() {
      axios
        .get(`${url}/teamsForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.turmas = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        });
    },
    registerStudent() {
      let validation = this.$refs.form.validate();

      if (validation) {
        axios
          .post(`${url}/studentsBySchoolClient`, this.formStudent, {
            headers: {
              authorization: token,
            },
          })
          .then(() => {
            this.StudentCard();
            this.closeRegister();
            this.getStudent();
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    async StudentCard() {
      let user = this.formStudent.user;
      let schoolData = await this.getSchoolData(this.formStudent.teamId);

      let body = {
        name: await this.shortenName(this.formStudent.name),
        user: user,
        school: schoolData.nickname,
        city: schoolData.city,
      };

      try {
        axios.post(`${url}/send-email/card`, body, {
          headers: {
            authorization: token,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    async getSchoolData(id) {
      const index = this.turmas.findIndex((item) => item.id === id);
      let schoolData = index !== -1 ? this.turmas[index].schoolId : null;

      const schoolIndex = this.schools.findIndex(
        (itm) => itm.id === schoolData
      );
      return schoolIndex !== -1 ? this.schools[schoolIndex] : null;
    },
    async shortenName(fullName) {
      fullName = await this.removeAccents(fullName);
      let names = fullName.split(" ");

      if (names.length <= 2) {
        return fullName.toUpperCase();
      }

      let firstName = names[0];
      let lastName = names[names.length - 1];
      let middleInitial = names
        .slice(1, names.length - 1)
        .map((name) => name.charAt(0))
        .join(". ");

      return `${firstName} ${middleInitial}. ${lastName}`.toUpperCase();
    },
    removeAccents(str) {
      let normalizedStr = str.normalize("NFD");
      let removedAccentsStr = normalizedStr.replace(/[\u0300-\u036f]/g, "");

      return removedAccentsStr;
    },
    async apleedByActividad(item) {
      try {
        let res = await axios.get(
          `${url}/appliedClassesAttendedSchool?student=${item.id}`,
          {
            headers: {
              authorization: token,
            },
          }
        );
        if (res.data.length == 0) {
          this.resuu = false;
          this.semAulasP = true;
        } else {
          this.semAulasP = false;
          this.resuu = true;
          this.appliedClassItem = res.data;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async openModalAtividade(item) {
      let res = await axios.get(
        `${url}/studentAnswersByStudent?student=${item.studentId}&lesson=${item.idAula}`,
        {
          headers: {
            authorization: token,
          },
        }
      );

      this.atividadeItems = res.data;

      this.atividadeItems.map((correct) => {
        if (correct.correct == 0) {
          correct.correct = this.$t('school.school_corecao.corecao1');
        } else {
          correct.correct = this.$t('school.school_corecao.corecao2');
        }
      });
    },
    updateStudent(id) {
      axios
        .put(`${url}/students/${id}`, this.formStudent, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getStudent();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/students/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getStudent();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.formStudent = {};
      this.editMode = false;
      this.$refs.form.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formStudent = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.formStudent = Object.assign({}, item);
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formStudent = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },
    atividade(item) {
      this.openModalAtividade(item);
      this.editedIndex = item.id;
      this.listOn = true;
    },
    closeAtividade() {
      this.listOn = false;
    },
    onAulasList(item) {
      this.apleedByActividad(item);
      this.editedIndex = item.id;
      this.listAulasOn = true;
    },
    closeAulasContainer() {
      this.listAulasOn = false;
    },
    searchByTeam() {
      this.search = localStorage.getItem("teamSingular");
      localStorage.removeItem("teamSingular");
    },
  },
  created() {
    this.getStudent();
    this.getSchools();
    this.getTeamsBySchool();
    this.searchByTeam();
  },
};
</script>
