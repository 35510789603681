var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"backcolor",staticStyle:{"height":"100%"}},[_c('v-toolbar',{staticClass:"elevation-1"},[_c('v-icon',{staticClass:"text-h4",attrs:{"color":"pink"}},[_vm._v("mdi-view-dashboard")]),_c('v-toolbar-title',{staticClass:"ml-3"},[_c('div',{staticClass:"d-flex flex-column"},[_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.smAndUp
              ? 'letter-spacing: 0.5rem'
              : 'letter-spacing: 0.2rem')},[_vm._v(" "+_vm._s(_vm.$t('school.school_visao'))+" ")]),_c('span',{staticClass:"font-weight-medium",staticStyle:{"font-size":"1rem"}},[_vm._v(" "+_vm._s(_vm.formatedDate)+" ")])])]),_c('v-spacer'),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.dateForFilter,"transition":"scroll-x-reverse-transition","offset-y":"","left":"","max-width":"290px","min-width":"auto"},on:{"update:returnValue":function($event){_vm.dateForFilter=$event},"update:return-value":function($event){_vm.dateForFilter=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mr-sm-2 mr-md-4",attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-filter")])],1)]}}]),model:{value:(_vm.dataPicker),callback:function ($$v) {_vm.dataPicker=$$v},expression:"dataPicker"}},[_c('v-date-picker',{attrs:{"type":"month","no-title":"","color":"blue","max":_vm.maxMonth,"header-color":"darkblue"},on:{"change":function($event){return _vm.selectDate(_vm.dateForFilter)}},model:{value:(_vm.dateForFilter),callback:function ($$v) {_vm.dateForFilter=$$v},expression:"dateForFilter"}})],1)],1),_c('v-row',{staticClass:"px-4 my-4 px-md-6 my-md-6"},[_c('v-col',{attrs:{"cols":"12","lg":"8"}},[_c('v-card',{staticClass:"rounded-lg"},[_c('v-card-title',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : '')},[_vm._v(" "+_vm._s(_vm.$t('school.school_numero'))+"  "),_c('b',[_vm._v(_vm._s(_vm.currentMonth))])]),_c('ChartAccess',{ref:"userChart",attrs:{"chartData":_vm.chartData}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"4"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"height":"100%"}},[_c('div',{staticClass:"grid-container pa-4"},[_c('div',{staticClass:"rounded-lg grid-item pink-bg"},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"darkpink"}},[_vm._v(" mdi-account-group ")]),_c('span',{staticClass:"font-weight-medium text-center"},[_vm._v(" "+_vm._s(_vm.$t('school.school_acesso'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.totalAccess)+" ")])],1),_c('div',{staticClass:"rounded-lg grid-item",style:({ backgroundColor: _vm.backgroundColor1, cursor: 'pointer'}),on:{"click":function($event){return _vm.PedidosResponsaveis(1)},"mouseover":function($event){return _vm.changeColor(1)},"mouseleave":function($event){return _vm.resetColor(1)}}},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"darkcyan"}},[_vm._v(" mdi-bag-personal ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.$t('school.school_aluno'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.totalStudent)+" ")])],1),_c('div',{staticClass:"rounded-lg grid-item green-bg",style:({ backgroundColor: _vm.backgroundColor2, cursor: 'pointer'}),on:{"click":function($event){return _vm.PedidosResponsaveis(2)},"mouseover":function($event){return _vm.changeColor(2)},"mouseleave":function($event){return _vm.resetColor(2)}}},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"green"}},[_vm._v(" mdi-account-tie ")]),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.$t('school.school_professor'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.totalTeacher)+" ")])],1),_c('div',{staticClass:"rounded-lg grid-item yellow-bg",style:({ backgroundColor: _vm.backgroundColor3, cursor: 'pointer'}),on:{"click":function($event){return _vm.PedidosResponsaveis(3)},"mouseover":function($event){return _vm.changeColor(3)},"mouseleave":function($event){return _vm.resetColor(3)}}},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"yellow"}},[_vm._v(" mdi-account-school ")]),_c('span',{staticClass:"font-weight-medium text-center"},[_vm._v(" "+_vm._s(_vm.$t('school.school_qtd.qtdturmas'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.teamAmmount)+" ")])],1),_c('div',{staticClass:"rounded-lg grid-item blue-bg"},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"blue"}},[_vm._v(" mdi-school ")]),_c('span',{staticClass:"font-weight-medium text-center"},[_vm._v(" "+_vm._s(_vm.$t('school.school_qtd.qtdAulas'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.classesData.length)+" ")])],1),_c('div',{staticClass:"rounded-lg grid-item red-bg",style:({ backgroundColor: _vm.backgroundColor4, cursor: 'pointer'}),on:{"click":function($event){return _vm.PedidosResponsaveis(4)},"mouseover":function($event){return _vm.changeColor(4)},"mouseleave":function($event){return _vm.resetColor(4)}}},[_c('v-icon',{staticClass:"mb-2",attrs:{"size":"40","color":"red"}},[_vm._v(" mdi-account-multiple-plus ")]),_c('span',{staticClass:"font-weight-medium text-center"},[_vm._v(" "+_vm._s(_vm.$t('school.school_qtd.qtdPedidos'))+" ")]),_c('span',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.mdAndUp
                  ? 'font-size: 1.5rem'
                  : 'font-size: 1.2rem')},[_vm._v(" "+_vm._s(_vm.ParentPendet)+" ")])],1)])])],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"min-height":"45vh","height":"100%"}},[_c('v-card-title',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : '')},[_vm._v(" "+_vm._s(_vm.$t('school.school_qtd.qtdAluno'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.studentHeader,"items":_vm.studentData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
              'items-per-page-options': [10, 25, 40, -1],
              'items-per-page-all-text': 'Todos',
              'items-per-page-text': 'Itens por página',
              'show-first-last-page': true,
            }},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"6"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"min-height":"45vh","height":"100%"}},[_c('v-card-title',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : '')},[_vm._v(" "+_vm._s(_vm.$t('school.school_qtd.qtdprofessor'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.teacherHeader,"items":_vm.teacherData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
              'items-per-page-options': [10, 25, 40, -1],
              'items-per-page-all-text': 'Todos',
              'items-per-page-text': 'Itens por página',
              'show-first-last-page': true,
            }},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}}})],1)],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"12"}},[_c('v-card',{staticClass:"rounded-lg",staticStyle:{"min-height":"45vh","height":"100%"}},[_c('v-card-title',{staticClass:"font-weight-bold",style:(_vm.$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : '')},[_vm._v(" "+_vm._s(_vm.$t('school.school_qtd.aulasaplica'))+" ")]),_c('v-card-text',[_c('v-data-table',{attrs:{"dense":"","headers":_vm.classesHeader,"items":_vm.classesData,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
              'items-per-page-options': [10, 25, 40, -1],
              'items-per-page-all-text': 'Todos',
              'items-per-page-text': 'Itens por página',
              'show-first-last-page': true,
            }},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}}})],1)],1),_c('v-snackbar',{staticClass:"mx-auto",attrs:{"timeout":"5000","elevation":"3","bottom":"","color":"yellow","outlined":"","text":""},scopedSlots:_vm._u([{key:"action",fn:function(ref){
            var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticClass:"font-weight-black",attrs:{"text":"","rounded":"","color":"yellow"},on:{"click":function($event){_vm.errorSnackbar = null}}},'v-btn',attrs,false),[_vm._v(" "+_vm._s(_vm.$t('botoes.botao_ok'))+" ")])]}}]),model:{value:(_vm.errorSnackbar),callback:function ($$v) {_vm.errorSnackbar=$$v},expression:"errorSnackbar"}},[_c('v-icon',{attrs:{"large":_vm.$vuetify.breakpoint.smAndUp ? true : false,"color":"yellow","left":""}},[_vm._v("mdi-alert-circle ")]),_c('span',{staticClass:"black--text text-subtitle-1"},[_vm._v(" "+_vm._s(_vm.$t('school.school_qtd.qtdResponsaveis'))+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }