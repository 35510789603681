<template>
  <div class="backcolor" style="height: 100%">
    <v-toolbar class="elevation-1">
      <v-icon color="pink" class="text-h4">mdi-view-dashboard</v-icon>
      <v-toolbar-title class="ml-3">
        <div class="d-flex flex-column">
          <span
            class="font-weight-bold"
            :style="
              $vuetify.breakpoint.smAndUp
                ? 'letter-spacing: 0.5rem'
                : 'letter-spacing: 0.2rem'
            "
          >
            {{ $t('school.school_visao') }}
          </span>
          <span class="font-weight-medium" style="font-size: 1rem">
            {{ formatedDate }}
          </span>
        </div>
      </v-toolbar-title>

      <v-spacer></v-spacer>

      <v-menu
        ref="menu"
        v-model="dataPicker"
        :close-on-content-click="false"
        :return-value.sync="dateForFilter"
        transition="scroll-x-reverse-transition"
        offset-y
        left
        max-width="290px"
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" icon class="mr-sm-2 mr-md-4">
            <v-icon>mdi-filter</v-icon>
          </v-btn>
        </template>
        <v-date-picker
          v-model="dateForFilter"
          @change="selectDate(dateForFilter)"
          type="month"
          no-title
          color="blue"
          :max="maxMonth"
          header-color="darkblue"
        >
        </v-date-picker>
      </v-menu>
    </v-toolbar>
    <v-row class="px-4 my-4 px-md-6 my-md-6">
      <v-col cols="12" lg="8">
        <v-card class="rounded-lg">
          <v-card-title
            class="font-weight-bold"
            :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
          >
            {{ $t('school.school_numero') }}&nbsp; <b>{{ currentMonth }}</b>
          </v-card-title>
          <ChartAccess :chartData="chartData" ref="userChart" />
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card class="rounded-lg" style="height: 100%">
          <div class="grid-container pa-4">
            <div class="rounded-lg grid-item pink-bg">
              <v-icon size="40" class="mb-2" color="darkpink">
                mdi-account-group
              </v-icon>
              <span class="font-weight-medium text-center">
                {{ $t('school.school_acesso') }}
              </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ totalAccess }}
              </span>
            </div>

            <div class="rounded-lg grid-item" @click="PedidosResponsaveis(1)" :style="{ backgroundColor: backgroundColor1, cursor: 'pointer'}" @mouseover="changeColor(1)" @mouseleave="resetColor(1)">
              <v-icon size="40" class="mb-2" color="darkcyan">
                mdi-bag-personal
              </v-icon>
              <span class="font-weight-medium">{{ $t('school.school_aluno') }} </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ totalStudent }}
              </span>
            </div>
            <div class="rounded-lg grid-item green-bg" @click="PedidosResponsaveis(2)" :style="{ backgroundColor: backgroundColor2, cursor: 'pointer'}" @mouseover="changeColor(2)" @mouseleave="resetColor(2)">
              <v-icon size="40" class="mb-2" color="green">
                mdi-account-tie
              </v-icon>
              <span class="font-weight-medium"> {{ $t('school.school_professor') }} </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ totalTeacher }}
              </span>
            </div>
            <div class="rounded-lg grid-item yellow-bg" @click="PedidosResponsaveis(3)" :style="{ backgroundColor: backgroundColor3, cursor: 'pointer'}" @mouseover="changeColor(3)" @mouseleave="resetColor(3)">
              <v-icon size="40" class="mb-2" color="yellow">
                mdi-account-school
              </v-icon>
              <span class="font-weight-medium text-center">
                {{ $t('school.school_qtd.qtdturmas') }}
              </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ teamAmmount }}
              </span>
            </div>

            <div class="rounded-lg grid-item blue-bg">
              <v-icon size="40" class="mb-2" color="blue">
                mdi-school
              </v-icon>
              <span class="font-weight-medium text-center">
                {{ $t('school.school_qtd.qtdAulas') }}
              </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ classesData.length }}
              </span>
            </div>
            <div class="rounded-lg grid-item red-bg"  @click="PedidosResponsaveis(4)" :style="{ backgroundColor: backgroundColor4, cursor: 'pointer'}" @mouseover="changeColor(4)" @mouseleave="resetColor(4)">
              <v-icon size="40" class="mb-2" color="red">
                mdi-account-multiple-plus
              </v-icon>
              <span class="font-weight-medium text-center">
                {{ $t('school.school_qtd.qtdPedidos') }}
              </span>
              <span
                class="font-weight-bold"
                :style="
                  $vuetify.breakpoint.mdAndUp
                    ? 'font-size: 1.5rem'
                    : 'font-size: 1.2rem'
                "
              >
                {{ ParentPendet }}
              </span>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card class="rounded-lg" style="min-height: 45vh; height: 100%">
          <v-card-title
            class="font-weight-bold"
            :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
          >
            {{ $t('school.school_qtd.qtdAluno') }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="studentHeader"
              :items="studentData"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :footer-props="{
                'items-per-page-options': [10, 25, 40, -1],
                'items-per-page-all-text': 'Todos',
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
              }"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="6">
        <v-card class="rounded-lg" style="min-height: 45vh; height: 100%">
          <v-card-title
            class="font-weight-bold"
            :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
          >
            {{ $t('school.school_qtd.qtdprofessor') }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="teacherHeader"
              :items="teacherData"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :footer-props="{
                'items-per-page-options': [10, 25, 40, -1],
                'items-per-page-all-text': 'Todos',
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
              }"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
      

      <v-col cols="12" lg="12">
        <v-card class="rounded-lg" style="min-height: 45vh; height: 100%">
          <v-card-title
            class="font-weight-bold"
            :style="$vuetify.breakpoint.xsOnly ? 'font-size: 1rem' : ''"
          >
            {{ $t('school.school_qtd.aulasaplica') }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              dense
              :headers="classesHeader"
              :items="classesData"
              @page-count="pageCount = $event"
              :page.sync="page"
              :items-per-page="itemsPerPage"
              :footer-props="{
                'items-per-page-options': [10, 25, 40, -1],
                'items-per-page-all-text': 'Todos',
                'items-per-page-text': 'Itens por página',
                'show-first-last-page': true,
              }"
            >
            </v-data-table>
          </v-card-text>
        </v-card>
        <v-snackbar
          v-model="errorSnackbar"
          timeout="5000"
          elevation="3"
          class="mx-auto"
          bottom
          color="yellow"
          outlined
          text
        >
          <v-icon
            :large="$vuetify.breakpoint.smAndUp ? true : false"
            color="yellow"
            left
          >mdi-alert-circle
          </v-icon>
          <span class="black--text text-subtitle-1">
            {{ $t('school.school_qtd.qtdResponsaveis') }}
          </span>
          <template v-slot:action="{ attrs }">
            <v-btn
              text
              v-bind="attrs"
              @click="errorSnackbar = null"
              rounded
              color="yellow"
              class="font-weight-black"
            >
            {{ $t('botoes.botao_ok') }}
            </v-btn>
          </template>
        </v-snackbar>
      </v-col>
    </v-row>
  </div>
</template>

<script>
const axios = require("axios");
const url = process.env.VUE_APP_API_URL;
const token = "Baerer " + localStorage.getItem("accessToken");
const authorization = { headers: { authorization: token } };

import ChartAccess from "./Charts/ChartAccess.vue";

export default {
  components: {
    ChartAccess,
  },
  data() {
    return {
      maxMonth: new Date().toISOString().substr(0, 7),
      dateForFilter: "",
      dataPicker: false,
      modal: false,
      formatedDate: "",
      currentMonth: "",
      studentAccess: [],
      chartData: { name: [], data: [[], []] },
      teacherHeader: [
        { text: this.$t('school.school_headers.headers1'), value: "name" },
        { text: this.$t('school.school_headers.heardes2'), value: "amt_access", align: "center" },
        { text: this.$t('school.school_headers.headers3'), value: "date", align: "center" },
      ],
      studentHeader: [
        { text: this.$t('school.school_headers.headers1'), value: "name" },
        { text: this.$t('school.school_headers.heardes2'), value: "amt_access", align: "center" },
        { text: this.$t('school.school_headers.headers3'), value: "date", align: "center" },
      ],
      classesHeader: [
        { text: this.$t('login.login_usuario.usuario3'), value: "Professor" },
        { text: this.$t('school.school_headers.heardes5'), value: "Turma", align: "center" },
        { text: this.$t('school.school_headers.heardes6'), value: "Kit", align: "center" },
        { text: this.$t('school.school_headers.heardes7'), value: "Aula", align: "center" },
        { text: this.$t('school.school_headers.heardes8'), value: "AulaDesc", align: "center" },
        { text: this.$t('school.school_headers.heardes9'), value: "Data", align: "center" },
      ],
      teacherData: [],
      studentData: [],
      classesData: [],

      ParentPendet: '',
      totalAccess: 0,
      totalStudent: 0,
      totalTeacher: 0,
      teamAmmount: 0,
      totalClasses: 0,

      page: 1,
      pageCount: null,
      itemsPerPage: 10,

      backgroundColor1: 'rgba(28, 254, 232, 0.2)',
      backgroundColor2: 'rgba(25, 255, 56, 0.2)',
      backgroundColor3: 'rgba(249, 194, 40, 0.2)',
      backgroundColor4: 'rgba(255, 0, 0, 0.2)',

      errorSnackbar: false,

    };
  },
  created() {
    this.getDate(new Date());
    this.getTeamAmmount();
  },
  methods: {
    changeColor(id) {
      switch (id) {
        case 1:
          this.backgroundColor1 = 'rgba(28, 254, 232, 0.6)';
          break;          
        case 2:
          this.backgroundColor2 = 'rgba(25, 255, 56, 0.6)';
          break;          
        case 3:
          this.backgroundColor3 = 'rgba(249, 194, 40, 0.6)';
          break; 
        case 4:
          this.backgroundColor4 = 'rgba(255, 0, 0, 0.6)';
          break;     
      }
    },
    resetColor(id) {
      switch (id) {
        case 1:
          this.backgroundColor1 = 'rgba(28, 254, 232, 0.2)';
          break;          
        case 2:
          this.backgroundColor2 = 'rgba(25, 255, 56, 0.2)';
          break;          
        case 3:
          this.backgroundColor3 = 'rgba(249, 194, 40, 0.2)';
          break;  
        case 4:
          this.backgroundColor4 = 'rgba(255, 0, 0, 0.2)';
          break;              
      }
    },
    PedidosResponsaveis(id){
      switch (id) {
        case 1:
          this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu11')
          break;          
        case 2:
          this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu10')
          break;          
        case 3:
          this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu13')
          break;        
        case 4:
          this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu25')
          break;
      }
    },
    getDate(date) {
      let month = (date.getMonth() + 1).toString().padStart(2, "0");
      let year = date.getFullYear();
      this.formateDate(date.getMonth(), year);

      this.selectDate(year + "-" + month, year);
    },
    selectDate(date) {
      this.dateForFilter = date;

      const [year, month] = date.split("-");
      let monthStr = parseInt(month).toString();
      this.formateDate(monthStr, year);
      this.getStudentAccess();
      this.getTeacherAccess();
      this.getAppliedClasses();
      this.GetRegisterNumber();
      this.getChartData();
      setTimeout(() => {
        this.dataPicker = false;
      }, 100);
    },
    async getStudentAccess() {
      let res = await axios.get(
        `${url}/access/studentForSchool/${this.dateForFilter}`,
        authorization
      );

      this.studentData = res.data.result;
    },
    async getTeacherAccess() {
      let res = await axios.get(
        `${url}/access/teacherForSchool/${this.dateForFilter}`,
        authorization
      );

      this.teacherData = res.data.result;
    },
    async getAppliedClasses() {
      let res = await axios.get(
        `${url}/appliedClassesByTeacher/0`,
        authorization
      );

      this.classesData = res.data;
    },
    async GetRegisterNumber() {
      let res = await axios.get(
        `${url}/GetRegisterNumber`,
        authorization
      );

      this.ParentPendet = res.data[0].number;
      if(this.ParentPendet === 0){
        this.errorSnackbar = false
      }else{
        this.errorSnackbar = true
      }
    },    

    

    async getTeamAmmount() {
      let res = await axios.get(`${url}/count/team`, authorization);
      this.teamAmmount = res.data.result[0].teamAmmount;
    },
    async getChartData() {
      let res = await axios.get(
        `${url}/access/userTypeForSchool/${this.dateForFilter}`,
        authorization
      );

      this.chartData = { name: [], data: [[], []] };
      for (let index = 0; index < res.data.result.length; index++) {
        const { month, teacher, student } = res.data.result[index];
        this.chartData.name.push(month);
        this.chartData.data[0].push(teacher);
        this.chartData.data[1].push(student);
      }

      this.$refs.userChart.updateData();

      this.totalStudent = res.data.result.reduce(
        (acc, curr) => acc + parseInt(curr.student),
        0
      );
      this.totalTeacher = res.data.result.reduce(
        (acc, curr) => acc + parseInt(curr.teacher),
        0
      );
      this.totalAccess = this.totalStudent + this.totalTeacher;
    },
    formateDate(month, year) {
      let monthNames = [
        { full: "Janeiro", abb: "Jan" },
        { full: "Fevereiro", abb: "Fev" },
        { full: "Março", abb: "Mar" },
        { full: "Abril", abb: "Abr" },
        { full: "Maio", abb: "Mai" },
        { full: "Junho", abb: "Jun" },
        { full: "Julho", abb: "Jul" },
        { full: "Agosto", abb: "Ago" },
        { full: "Setembro", abb: "Set" },
        { full: "Outubro", abb: "Out" },
        { full: "Novembro", abb: "Nov" },
        { full: "Dezembro", abb: "Dez" },
      ];
      this.currentMonth = monthNames[month - 1].full;
      this.formatedDate = this.currentMonth + ", " + year;
    },
  },
};
</script>

<style scoped>
.grid-container {
  display: grid;
  grid-template: 1fr 1fr / 1fr 1fr;
  gap: 12px;
  height: 100%;
}

.pink-bg {
  background-color: rgba(253, 128, 205, 0.2);
}

.cyan-bg {
  background-color: rgba(28, 254, 232, 0.2);
}

.yellow-bg {
  background-color: rgba(249, 194, 40, 0.2);
}

.green-bg {
  background-color: rgba(25, 255, 56, 0.2);
}

.blue-bg {
  background-color: rgba(0, 120, 255, 0.2);
}

.red-bg {
  background-color: rgba(255, 0, 0, 0.2);
}

.grid-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px 0 16px 0;
}

.grid-item:hover {
  -webkit-animation: flip-vertical-right 0.4s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: flip-vertical-right 0.4s cubic-bezier(0.455, 0.03, 0.515, 0.955)
    both;
}
</style>
