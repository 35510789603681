<template>
  <div class="backcolor">
    <v-toolbar dense class="elevation-1">
      <v-icon color="blue" class="text-h4">mdi-certificate</v-icon>
      <v-toolbar-title
        :style="
          $vuetify.breakpoint.smAndUp
            ? 'letter-spacing: 0.5rem'
            : 'letter-spacing: 0.2rem'
        "
        class="ml-3 font-weight-bold"
        >{{ $t('certificado.cert_gerar') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>

      <v-dialog v-model="dialog" persistent max-width="600px">
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="darkpink"
            rounded
            dark
            v-bind="attrs"
            v-on="on"
            @click="formTeacher = {}"
          >
            <v-icon left> mdi-plus-thick </v-icon>
           {{ $t('botoes.botao_gerarcert') }}
          </v-btn>
          <v-btn color="darkpink" rounded dark v-on="on" style="left: -1%" @click="MenuProfessor()">
            <v-icon left> mdi-human-male-board </v-icon>
            {{ $t('certificado.cert_verprofessor') }}
          </v-btn>
        </template>
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('school.school_novoprofessor') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col class="d-flex" cols="12" sm="6">
                   <v-select
                      color="blue"
                      v-model="formTeacher.teacherId"
                      :items="teachers"
                      :rules="[rules.required]"
                      :label="$t('label.label_professor')"
                      item-text="name"
                      item-value="id"
                      required
                      @change="doUser(formTeacher.teacherId)"
                    ></v-select>
                  </v-col>
                  <v-col class="d-flex" cols="12" sm="6">
                    <v-text-field
                      color="darkblue"
                      v-model="formTeacher.courseName"
                      :rules="[rules.required]"
                      :label="$t('label.label_curso')"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="menuIniDate"
                      v-model="menuIniDate"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="formTeacher.dateBegin"
                          :label="$t('label.label_datas.inicial')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="formTeacher.dateBegin"
                        :active-picker.sync="activePicker"
                        min="1950-01-01"
                        @change="saveDateInc"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-menu
                      ref="menuFinDate"
                      v-model="menuFinDate"
                      :close-on-content-click="false"
                      offset-x
                      transition="slide-x-transition"
                      min-width="auto"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          color="darkblue"
                          v-model="formTeacher.dateEnd"
                          :label="$t('label.label_datas.final')"
                          append-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        >
                        </v-text-field>
                      </template>
                      <v-date-picker
                        locale="pt-BR"
                        color="darkblue"
                        header-color="darkblue"
                        v-model="formTeacher.dateEnd"
                        :active-picker.sync="activePicker"
                        min="1950-01-01"
                        @change="saveDateFin"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="darkblue"
                      :label="$t('label.label_carga')"
                      :rules="[rules.numbersOnly]"
                      v-model="formTeacher.workload"
                      required
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>{{ $t('regras.regra_obrigatorio') }}*</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" text rounded @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn
              color="darkpink"
              text
              rounded
              @click="
              editMode ? updateTeacher(formTeacher.id) : registerTeacher()
              "
            >
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="text-h5"
          >Tem certeza que deseja excluir?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="closeDelete"
            >{{ $t('botoes.botao_cancelar') }}</v-btn
          >
          <v-btn color="blue darken-1" text @click="deleteItemConfirm"
            >{{ $t('botoes.botao_confirma') }}</v-btn
          >
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="ma-4">
      <v-col
        cols="12"
        md="6"
        lg="4"
        v-for="certificado in Cert"
        :key="certificado.id"
      >
        <v-card class="rounded-lg py-4 px-2" style="height: 100%">
          <div
            class="
              d-flex
              flex-column
              align-center
              justify-space-around
              text-center
            "
            style="height: 100%; min-height: 25vh"
          >
            <div class="font-weight-bold" style="font-size: 1.2rem">
              {{ $t('certificado.cert_simples.conteudo1') }}
            </div>
            <div class="mb-4 font-weight-medium" style="font-size: 0.9rem">
              {{ $t('certificado.cert_simples.conteudo2') }} {{certificado.courseName}}
            </div>
            <div class="mb-4 font-weight-medium" style="font-size: 0.9rem">
              {{ $t('certificado.cert_simples.conteudo3') }} {{ certificado.teacherName }}
            </div>

            <div style="font-size: 0.9rem">
              <div>{{ $t('certificado.cert_simples.conteudo4') }} {{ certificado.dateBegin.split('-').reverse().join('/')  }}</div>
              <div>{{ $t('certificado.cert_simples.conteudo5') }} {{ certificado.dateEnd.split('-').reverse().join('/')  }}</div>
              <div>{{ $t('certificado.cert_simples.conteudo6') }} {{ certificado.workload }} {{ $t('certificado.cert_simples.conteudo7') }}</div>
            </div>
            <div class="mt-6">
              <v-btn
                rounded
                outlined
                small
                icon
                @click="editItem(certificado)"
                color="darkblue"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
              <v-btn
                rounded
                outlined
                small
                icon
                @click="deleteItem(certificado)"
                color="error"
                class="px-2 mx-1"
              >
                <v-icon small>mdi-delete</v-icon>
              </v-btn>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>

      <!-- Diálogo para listar as aulas aplicadas -->
      <v-dialog v-model="appliedClassesDialog" persistent max-width="600px">
        <v-card>
          <v-card-title>
            {{ $t('student.student_aplicado') }} {{ selectedTeacherClasses.length > 0 ? selectedTeacherClasses[0].Professor : "" }}
            <v-spacer></v-spacer>
            <v-btn icon @click="appliedClassesDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
        </v-card>
      </v-dialog>
    </div>
  </template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      dialog: null,
      formTeacher: {},
      search: "",
      headers: [
        { text: this.$t('certificado.cert_headers.headers1'), value: "name" },
        { text: this.$t('certificado.cert_headers.headers2'), value: "user" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      teachers: [],
      editedIndex: -1,
      menuIniDate: false,
      menuFinDate: false,      
      activePicker: null,
      dialogDelete: null,
      editMode: false,
      Cert: '',
      userExisting: false,
      rules: {
        numbersOnly: value => /^[0-9]+$/.test(value) || this.$t('regras.regra_numero'),
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        none: true,
      },
      schools: [],
      selectedSchool: [],
      varias: null,
      searchDialog: true,
      teachersNot: [],
      searchTeachers: "",
      // IDdaEscola: 15,
      showPassword: false,

      appliedClassesDialog: false,
      selectedTeacherClasses: [],
      classColors: [
        { light: "pink ", dark: "darkpink" },
        { light: "cyan", dark: "darkcyan" },
        { light: "yellow", dark: "darkyellow" },
      ],
    };
  },
    watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
    date() {
      this.dateFormattedInic = this.formatDate(this.formTeam.InicDate);
      this.dateFormattedFinal = this.formatDate(this.formTeam.FinalDate);
    },
  },  
  methods: {
    MenuProfessor() {
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu10')
    },
    async doUser(id) {
      for (let i=0; i < this.teachers.length; i++)  {
	      if(id == this.teachers[i].id ){
          this.formTeacher.teacherName = this.teachers[i].name
        }
      }
    },
    saveDateInc(date) {
      this.$refs.menuIniDate.save(date);
      
    },    
    saveDateFin(date) {   
      this.$refs.menuFinDate.save(date);
    },   
    async verifyUser(res) {
      let response = await axios.get(`${url}/clients/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });
      if (response.data != null) {
        this.userExisting = true;
      } else {
        this.userExisting = false;
      }
    },
    openSearchTeachers() {
      this.getTeachersNotLinked();
      this.searchDialog = true;
    },
    inscriptionTeacher(item) {
      const data = { teacherId: item.id };
      axios
        .post(`${url}/teacherInscriptions`, data, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.getTeachersNotLinked();
          this.getTeacherBySchool();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeachersNotLinked() {
      axios
        .get(`${url}/teachersNot`)
        .then((res) => {
          this.teachersNot = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeacherBySchool() {
      axios
        .get(`${url}/teachersForSchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.teachers = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getTeacherCert() {
      axios
        .get(`${url}/teachersCert`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Cert = res.data
        })
        .catch((error) => {
          console.error(error);
        });
    },
    registerTeacher() {
      this.$refs.form.validate();
      axios
        .post(`${url}/InsertteacherCert`, this.formTeacher, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getTeacherCert();
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },
    updateTeacher(id) {
      axios
        .put(`${url}/updateteacherCert/${id}`, this.formTeacher, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          console.log(this.formTeacher)
          this.closeRegister();
          this.getTeacherCert();
        })
        .catch((error) => {
          console.error(error);
          alert("Error");
        });
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/deleteteacherCert/${this.formTeacher.id}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeRegister();
          this.getTeacherCert();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.dialogDelete = false
      this.formTeacher = {};
      this.editMode = false;
      this.$refs.form.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formTeacher = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      this.editedIndex = item.id;
      this.formTeacher = Object.assign({}, item);
      this.dialogDelete = true;
    },
    editItem(item) {
      this.editedIndex = item.id;
      this.formTeacher = Object.assign({}, item);
      this.dialog = true;
      this.editMode = true;
    },
    viewAppliedClasses(teacher) {
      axios
        .get(`${url}/appliedClassesByTeacher/${teacher.id}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.selectedTeacherClasses = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
      this.appliedClassesDialog = true;
    },
    colorLookLight(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].light;
      return color;
    },
    colorLookDark(index) {
      let position = (index + 1) % 3;
      let color = this.classColors[position].dark;
      return color;
    },
  },
  created() {
    this.getTeacherBySchool();
    this.getTeacherCert();
  },
};
</script>
