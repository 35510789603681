<template>
  <div>
    <apexchart
      type="bar"
      :height="$vuetify.breakpoint.smAndDown ? '100%' : 380"
      :options="chartOptions"
      :series="series"
    />
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";

export default {
  name: "App",
  props: {
    chartData: {
      name: [],
      data: [],
    },
  },
  components: {
    apexchart: VueApexCharts,
  },
  data() {
    return {
      series: [],
      chartOptions: {
        legend: {
          position: "top",
          fontSize: "14px",
        },
        chart: {
          type: "bar",
          height: 450,
        },
        menu: {
          offsetY: 10,
          offsetX: 10,
          fontSize: "14px",
          width: 150,
        },
        colors: ["#197CFF", "#F9C228"],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "75%",
            endingShape: "rounded",
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          show: true,
          width: 2,
          colors: ["transparent"],
        },
        xaxis: {
          type: "datetime",
          labels: {
            formatter: function (value) {
              var date = new Date(value);
              var day = date.getDate();
              var month = date.getMonth();
              var monthsAbbrPt = [
                "Jan",
                "Fev",
                "Mar",
                "Abr",
                "Mai",
                "Jun",
                "Jul",
                "Ago",
                "Set",
                "Out",
                "Nov",
                "Dez",
              ];
              var monthAbbrPt = monthsAbbrPt[month];
              return `${day} ${monthAbbrPt}`; // Format the date in Brazilian date format
            },
          },
        },
        fill: {
          opacity: 1,
        },
        noData: {
          text: this.$t('alerta.alerta_numresultado'),
          align: "center",
          verticalAlign: "middle",
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "16px",
          },
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
      },
    };
  },
  created() {
    this.updateData();
  },
  methods: {
    updateData() {
      setTimeout(() => {
        const { name, data } = this.chartData;

        this.series = [
          { name: this.$t('admin.admin_menu.menu11'), data: data[1] },
          { name: this.$t('admin.admin_menu.menu10'), data: data[0] },
        ];

        this.chartOptions = {
          xaxis: {
            categories: name,
          },
        };
      }, 500);
    },
  },
};
</script>

<style></style>
