<template>
  <div>
    <v-toolbar dense class="elevation-1">
      <v-icon color="blue" class="text-h4">mdi-account-multiple-plus</v-icon>
      <v-toolbar-title
        style="letter-spacing: 0.5rem"
        class="ml-3 font-weight-bold"
        >{{ $t('school.school_pedidosresponsavel') }}
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-dialog
        persistent
        max-width="600px"
        :fullscreen="$vuetify.breakpoint.mobile"
      >
        <template v-slot:activator="{ on }">
          <v-btn color="darkpink" rounded dark v-on="on" @click="MenuParent()">
            <v-icon left> mdi-human-male-female </v-icon>
            {{ $t('school.school_verresponsavel') }}
          </v-btn>
        </template>
      </v-dialog>
      <v-dialog v-model="listOn" max-width="800px">
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('admin.admin_menu.menu19') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-data-table
                :headers="atividadeHeader"
                :items="atividadeItems"
                :footer-props="{
                  'items-per-page-options': [20, 45, 60, 100, -1],
                  'items-per-page-all-text': $t('events.events_headers.headers1'),
                  'items-per-page-text': $t('student.student_paginas'),
                  'show-first-last-page': true,
                }"
              >
              </v-data-table>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeAtividade()">
              {{ $t('botoes.botao_sair') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialog" persistent max-width="600px">
        <v-card class="rounded-lg">
          <v-card-title class="darkblue white--text">
            <span class="text-h5">{{ $t('school.school_novoresponsavel') }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-form ref="form">
                <v-row>
                  <v-col cols="12" sm="6">
                    <v-text-field 
                      color="blue"
                      v-model="formParent.parentName"
                      :rules="[rules.required]"
                      :label="$t('label.label_nome')"
                      required
                      @change="doUser(formParent.parentName)"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <v-text-field
                      color="blue"
                      v-model="formParent.user"
                      :rules="[rules.required, rules.user]"
                      :label="$t('label.label_usuario')"
                      :loading="userLoad"
                      readonly
                    ></v-text-field>
                  </v-col>
                  <!-- <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      v-model="formParent.grade"
                      :items="grades"
                      :rules="[rules.required]"
                      label="Grade*"
                      required
                    ></v-select>
                  </v-col> -->
                  <v-col cols="12" sm="6">
                    <v-select
                      color="blue"
                      v-model="formParent.studentsId"
                      :items="Students"
                      :rules="[rules.required]"
                      :label="$t('label.label_aluno')"
                      item-text="name"
                      item-value="id"
                      required
                      @change="doUserClass(formParent.studentsId)"
                    ></v-select>
                  </v-col>
                  <v-col cols="6">
                    <v-text-field
                      v-if="!editMode"
                      color="blue"
                      v-model="formParent.password"
                      :rules="[rules.required, rules.length(4)]"
                      :label="$t('label.label_senha.label_senhaob')"
                      :type="showPassword ? 'text' : 'password'"
                      required
                      :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="showPassword = !showPassword"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
            <small>{{ $t('regras.regra_obrigatorio') }}*</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="darkpink" rounded text @click="closeRegister()">
              {{ $t('botoes.botao_cancelar') }}
            </v-btn>
            <v-btn
              color="darkpink"
              rounded
              text
              @click="
                registerParent(formParent)
              "
            >
              {{ $t('botoes.botao_salva') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-toolbar>
    <v-dialog v-model="dialogDelete" max-width="550px">
      <v-card
        class="rounded-lg py-3 error--text"
        style="border-left: 12px inset red"
      >
        <v-card-title>
          <v-icon left large color="error">mdi-alert-circle</v-icon>
          {{ $t('alerta.alerta_title') }}
        </v-card-title>
        <v-card-text class="black--text">
          {{ $t('alerta.alerta_rejeitar') }}
        </v-card-text>
        <v-card-actions class="d-flex justify-end pr-4">
          <v-btn
            color="error"
            dark
            rounded
            class="px-3"
            @click="deleteItemConfirm"
          >
           {{ $t('botoes.botao_rejeitar') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card class="elevation-0 mt-1">
      <v-card-title>
        <span v-if="$vuetify.breakpoint.smAndUp">{{ $t('admin.admin_menu.menu27') }}</span>
        <v-spacer></v-spacer>
        <v-text-field
          color="blue"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$vuetify.breakpoint.smAndUp ? $t('label.label_pesquisa') : ''"
          single-line
          hide-details
          :style="
            $vuetify.breakpoint.smAndUp ? 'max-width: 30%' : 'max-width: 60%'
          "
        >
        </v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="Parents"
        :search="search"
        :footer-props="{
          'items-per-page-options': [20, 45, 60, 100, -1],
          'items-per-page-all-text': $t('events.events_headers.headers1'),
          'items-per-page-text': $t('student.student_paginas'),
          'show-first-last-page': true,
        }"
      >
        <template v-slot:[`item.actions`]="{ item }">
          <v-btn
            rounded
            outlined
            small
            @click="editItem(item)"
            color="green"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-check</v-icon>
            {{ $t('botoes.botao_aceitar') }}
          </v-btn>
          <v-btn
            rounded
            outlined
            small
            @click="deleteItem(item)"
            color="error"
            class="px-2 mx-1"
          >
            <v-icon small class="mr-1">mdi-close</v-icon>
            {{ $t('botoes.botao_rejeitar') }}
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
            <v-snackbar
      v-model="successSnackbar"
      timeout="2500"
      elevation="3"
      class="mx-auto"
      bottom
      color="success"
      outlined
      text
    >
      <v-icon large color="success" class="mr-2">mdi-check-circle </v-icon>
      <span class="subtitle-1 black--text">
        {{ $t('alerta.alerta_sucesso') }}
      </span>
      <template v-slot:action="{ attrs }">
        <v-btn
          text
          v-bind="attrs"
          @click="successSnackbar = false"
          rounded
          color="success"
          class="font-weight-black"
        >
          {{ $t('botoes.botao_ok') }}
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<style scoped></style>

<script>
const url = process.env.VUE_APP_API_URL;
const axios = require("axios");
const token = "Baerer " + localStorage.getItem("accessToken");
export default {
  data() {
    return {
      activePicker: null,
      menu: false,
      dateFormatted: null,
      resuu: false,
      semAulasP: false,
      listOn: null,
      dialog: null,
      formParent: {},
      search: "",
      headers: [
        { text: this.$t('admin.admin_menu.menu27'), value: "parentName" },
        { text: this.$t('admin.admin_menu.menu13'), value: "teamName" },
        { text: this.$t('admin.admin_menu.menu11'), value: "studentName" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      headersDasAulas: [
        { text: this.$t('admin.admin_menu.menu18'), value: "title" },
        { text: this.$t('admin.admin_menu.menu2'), value: "course" },
        { text: "", value: "actions", sortable: false, align: "end" },
      ],
      atividadeHeader: [
        { text: this.$t('school.school_headeratividade.headers1'), value: "content" },
        { text: this.$t('school.school_headeratividade.headers2'), value: "correct" },
      ],
      atividadeItems: [],
      appliedClassItem: [],
      Students: [],
      Parents: [],
      editedIndex: -1,
      dialogDelete: null,
      editMode: false,
      resetDialog: false,
      showPassword: false,
      successSnackbar: false,
      rules: {
        email: (v) =>
          !!(v || "").match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) || this.$t('regras.regra_email'),
        length: (len) => (v) =>
          (v || "").length >= len ||
          `${this.$t('regras.regra_senhaMini.senha1')} ${len} ${this.$t('regras.regra_senhaMini.senha2')}`,
        maxLength: (len) => (v) =>
          (v || "").length <= len ||
          `${this.$t('regras.regra_senhaMax.senha1')} ${len} ${this.$t('regras.regra_senhaMax.senha2')}`,
        required: (v) => !!v || this.$t('regras.regra_obrigatorio'),
        option: (v) => !!v || this.$t('regras.regra_opcao'),
        image: (v) => !!v || this.$t('regras.regra_image'),
        question: (v) => !!v || this.$t('regras.regra_question'),
        date: (v) => !!v || this.$t('regras.regra_data'),
        user: (v) =>
          !!(v || "").match(/^[a-zA-Z0-9.]*$/) ||
          `${this.$t('regras.regra_user')}`,
        exist: () => !this.userExisting || this.$t('regras.regra_existeuser'),
        none: true,
      },
      nameSchool: null,
      schoolName: [],
      userExisting: false,
      userLoad: false,
      generatedCode: '',
      schools: [],
      turmas: [],
      grades: [
        "1° Infantil",
        "2° Infantil",
        "3° Infantil",
        "4° Infantil",
        "1° Fundamental 1",
        "2° Fundamental 1",
        "3° Fundamental 1",
        "4° Fundamental 1",
        "5° Fundamental 1",
        "6° Fundamental 2",
        "7° Fundamental 2",
        "8° Fundamental 2",
        "9° Fundamental 2",
        "1° Médio",
        "2° Médio",
        "3° Médio",
      ],
      // IDdaEscola: 8  //dps pegar o id correto do localStorage
    };
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
   methods: {
    async sendEmail(email) {
      try {
        axios.post(`${url}/send-email/Reponsavel`, email, {
          headers: {
            authorization: token,
          },
        });
      } catch (error) {
        console.error(error);
      }
    },
    resetItem(item) {
      this.editedIndex = item.id;
      this.formParent = Object.assign({}, item);
      this.formParent.password = ''
      this.resetDialog = true;
    },
    async doUser(name) {
      name = await this.removeAccents(name);
      this.userLoad = true;
      const array = await name.split(" ");
      let user = array[0] + "." + array[array.length - 1];
      let result = await this.verifyUser(user);
      let i = 1;
      let tryUser = user;
      while (result) {
        tryUser = user + `${i}`;
        result = await this.verifyUser(tryUser);
        i++;
      }
      setTimeout(() => {
        user = tryUser;
        this.formParent.user = user.toLowerCase();
        this.userLoad = false;
      }, 1000);
    },

    MenuParent() {
      this.$parent.$parent.$parent.page = this.$t('admin.admin_menu.menu27')
    },

    async doUserClass(idUser) {
      let response = await axios.get(`${url}/studentsById/id?id=${idUser}`, {
        headers: {
          authorization: token,
        },
      })
      this.formParent.studentName = response.data.result[0].name
   },

    save(date) {
      this.$refs.menu.save(date);
    },
    async verifyUser(res) {
      let response = await axios.get(`${url}/OneParents/user?user=${res}`, {
        headers: {
          authorization: token,
        },
      });
      //console.log(response.data.result[0])

      if (response.data.result[0] !== undefined) {
        this.userExisting = true;
        return true;
      } else {
        this.userExisting = false;
        return false;
      }
    },
    getTeamsByGrade(grade) {
      axios
        .get(`${url}/teamByGradeSchool/?grade=${grade}`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.turmas = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getParents() {
      axios
        .get(`${url}/getRegister`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Parents = res.data
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getStudent() {
      axios
        .get(`${url}/studentsBySchool`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.Students = res.data;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    getSchools() {
      axios
        .get(`${url}/schools`, {
          headers: {
            authorization: token,
          },
        })
        .then((res) => {
          this.schools = res.data;
        });
    },
    registerParent() {
      let validation = this.$refs.form.validate();
      this.generateCode()
      let body = {
        name: this.formParent.parentName,
        user: this.formParent.user,
        studentsId: this.formParent.studentsId,
        studentName: this.formParent.studentName,
        password: this.formParent.password,
        email: this.formParent.email
      }
      if (validation) {
        axios
          .put(`${url}/Insertparent`, body, {
            headers: {
              authorization: token,
            },
          })
          .then(() => {
            setTimeout(() => {
              this.successSnackbar = true;
            }, 1000);
            this.FinalRegisterParent()
            this.closeRegister();
            this.getParents();
            this.sendEmail(body)
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    FinalRegisterParent() {
      axios
        .put(`${url}/UpdateparentRegister/${this.editedIndex}`, this.formParent, {
          headers: {
            authorization: token,
          },
        })
        .catch((error) => {
          console.error(error);
        });      
    },
    async getSchoolData(id) {
      const index = this.turmas.findIndex((item) => item.id === id);
      let schoolData = index !== -1 ? this.turmas[index].schoolId : null;

      const schoolIndex = this.schools.findIndex(
        (itm) => itm.id === schoolData
      );
      return schoolIndex !== -1 ? this.schools[schoolIndex] : null;
    },
    async shortenName(fullName) {
      fullName = await this.removeAccents(fullName);
      let names = fullName.split(" ");

      if (names.length <= 2) {
        return fullName.toUpperCase();
      }

      let firstName = names[0];
      let lastName = names[names.length - 1];
      let middleInitial = names
        .slice(1, names.length - 1)
        .map((name) => name.charAt(0))
        .join(". ");

      return `${firstName} ${middleInitial}. ${lastName}`.toUpperCase();
    },
    removeAccents(str) {
      let normalizedStr = str.normalize("NFD");
      let removedAccentsStr = normalizedStr.replace(/[\u0300-\u036f]/g, "");

      return removedAccentsStr;
    },
    deleteItemConfirm() {
      axios
        .delete(`${url}/DeleteRegisterParent/${this.editedIndex}`, {
          headers: {
            authorization: token,
          },
        })
        .then(() => {
          this.closeDelete();
          this.getParents();
          setTimeout(() => {
            this.successSnackbar = true;
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    closeRegister() {
      this.dialog = false;
      this.formParent = {};
      this.editMode = false;
      this.$refs.form.resetValidation();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.formParent = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItem(item) {
      console.log(item.id)
      this.editedIndex = item.id;
      this.formParent = Object.assign({}, item);
      this.dialogDelete = true;
    },
    async editItem(item) {
    this.doUser(item.parentName)
    this.editedIndex = item.id;
    this.formParent = Object.assign({}, item);
    this.dialog = true;
    this.editMode = true;
    },
    generateCode() {
      const letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      let code = '';
          
      // Gerar 8 dígitos
      for (let i = 0; i < 8; i++) {
        code += Math.floor(Math.random() * 10);
      }
          
      // Adicionar uma letra aleatória
      code += letters.charAt(Math.floor(Math.random() * letters.length));
          
      this.formParent.password = code;
    },    
  },
  created() {
    this.getParents();
    this.getStudent()
    this.getSchools();
  },
};
</script>
